const TZ = "America/Sao_Paulo"

export function formattedDate(date: string): string {
  const dayjs = useDayjs()

  try {
    return dayjs(date).tz(TZ).format("DD/MM/YYYY")
  } catch (e) {
    console.log(e)

    throw e
  }
}

export function localizedDate(date: string): string {
  const dayjs = useDayjs()
  console.log(date)

  return dayjs(date).tz(TZ).locale('pt-br').format("DD [de] MMMM [de] YYYY")
}